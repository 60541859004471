.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  
}

body {
  padding: 0;
  margin: 0;
}